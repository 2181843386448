body {
  color: $font-color;
}
label,
.label {
  color: $label-color;
}
.bg-white {
  background: $bg-white;
}
.bg-offwhite {
  background-color: #f2f2f2;
}
