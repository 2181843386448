/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Rubik:wght@300;400&display=swap");
@import '~bootstrap/scss/bootstrap';
@import '../../node_modules/@naadi/framework/src/scss/base';
@import '../../node_modules/@naadi/framework/src/scss/common';
$border-radius: 8px;
@import './bootstrap';
@import '_variables', '_theme', '_layout', '_bootstrap-override','_responsive', '_media', '_fonts',
  'offcanvasmenu', 'typo', 'modal', 'accordian', '_form', '_button', '_modal','_main-page','_screenoverlay','_design-page';

@import './scroll-bar';
.cursor-pointer {
  cursor: pointer;
}
body{
  background: #FFFFFF;
}
.body-background {
  background: #ffffff;
  &.bg-offwhite {
    background-color: #f2f2f2;
  }
}
.Toastify__toast-body>div {
  display: flex;
  align-items: center;
}
.Toastify__toast--warning {
  background: #2b3440;
  color: #fff;
}
.Toastify__toast--error {
  background: #2b3440;
  color: #fff;
  z-index: 10000;
}
.Toastify__close-button {
  align-self: center;
}
.Toastify__toast {
  border-radius: 6px;
}

.column-busy {
  &::after {
    content: ' ';
    @extend .position-absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 2px;
    background-color: $primary;
  }
}

.td-busy {
  height: 1px;
  background-color: #dee2e6;
  @extend .border-0, .p-0;
  &.on {
    animation: TdBusyAnimation 0.6s infinite;
  }
}

@keyframes TdBusyAnimation {
  0% {
    background-color: #dee2e6;
  }
  100% {
    background-color: $primary;
  }
}

.form-control {
  &,
  &:focus {
    background-color: transparent;
  }
}

.naadi-modal-default-bound {
  max-width: 500px;
}

.dropdown-toggle::after {
  top: 14px;
  right: 8px;
  @extend .position-absolute;
}

.select-list-group-container {
  z-index: 99;
}
.pr-32{
  padding-right: 32px;
}
.pe-32{
  padding-right: 32px;
}
