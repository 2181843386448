.design-main-container{
  max-width: 1440px;
  
}
.design-highlight {
  color: #2563eb;
}

.design-normal {
  color: #1f2937;
}

.design-container{
  margin-left: 148px;
  @media (max-width: 768px) {
    margin-left: 0px;
  }
}

.design-header-text {
color: var(--Buttons-Primary, #006EED);
font-family: Roboto;
font-style: normal;
line-height: normal;
padding: 50px;
//max-width: 645px;

  h2 {
      font-size: 48px;
      font-weight: 700;
      width: 100%;
      text-align: left;
      .bold {
        font-weight: bold;
        color: var(--Text-Primary, #323232);
      }

      .highlight {
        color: var(--Buttons-Primary, #006EED);
        font-weight: bold;
      }
      @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 400;
      //max-width: 420px;
      width: 100%;
      text-align: center;
      }
    }

    p {
      font-family: Rubik;
      font-size: 32px;
      color: var(--Text-Primary, #323232);
      max-width: 600px;
      text-align: left;
      width: 100%;
      @media (max-width: 768px) {
        font-size: 15px;
        font-weight: 400;
        max-width: 350px;
        text-align: center;
        padding: auto;
      }
    }
    @media (max-width: 768px) {
      text-align: '-webkit-center'}
      padding: 20px;
    }

/* DesignBanner Styles */
.design-banner {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 50px;
  color: #0b1623;
  display: flex;
  align-items: center;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
  background-color: #f2f2f2cc;
  height: 220px;
  width: 80%;
  padding: 1rem;
  flex-wrap: wrap;
  margin-top: 117px;
}

.design-banner-text {
  padding-left: 70px;
  padding-right: 30px;
}

.design-banner-logo {
  display: flex;
  width: 336.964px;
  height: 51px;
  padding-right: 0.313px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  aspect-ratio: 336.96/51.00;
}


@media (max-width: 768px) {
  .design-banner {
    flex-direction: row; /* Ensure row layout */
    flex-wrap: nowrap; /* Prevent wrapping */
    justify-content: center; /* Center align */
    align-items: center; /* Vertical alignment */
    text-align: center;
    padding: 1rem;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    height: auto;
    margin-top: 48px;
  }

  .design-banner-text {
    padding-left: 0px;
    padding-right: 10px;
    font-size: 16px; /* Adjust font size for better fit */
  }

  .design-banner-logo {
    max-width: 80px; /* Reduce image size */
    height: auto;
  }
}

.design-tool-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: wrap; // Ensures wrapping on smaller screens
  //margin-left: 148px;
  margin-top: 173px;
  justify-content: center;
}

.design-justify-content{
  justify-content: center;
}
.design-tool-image {
  object-fit: cover;
  border-radius: 0.5rem;
  width: 425.758px;
  height: 500px;
}

.design-tool-text {
  font-size: 1.25rem;
   font-weight: 700;
  font-size: 48px;
  padding-left: 70px;
}

.design-tool-text-mob {
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

@media (max-width: 768px) {
  .design-tool-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: wrap; // Ensures wrapping on smaller screens
  margin-top: 42px;
}

.design-tool-image {
  object-fit: cover;
  border-radius: 0.5rem;
  width: 158.382px;
  height: 186px;
  //margin-left: 101px;
}

.design-tool-text {
  font-size: 1.25rem;
   font-weight: 700;
  font-size: 20px;
  padding-left: 100px;
}
}

.cabinet-catalog {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.home-designed{
  color: #000;
  text-align: center;
  font-family: Rubik;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 215px;
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 700;
    margin-top: 188px;
  }
}

.custom-button {
  background: transparent;
  border: 2px solid #007bff;
  color: #007bff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  display: inline-block;
  margin-top: 109px;
  width: 360px;
  height: 48px;
  &:hover {
    background: #007bff;
    color: white;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 4px 2px rgba(0, 123, 255, 0.5);
  }
  @media (max-width: 768px) {
    margin-top: 88px;
    width: 137px;
    font-size: 12px;
  }
}
.design-btn-home {
  color: var(--Buttons-Primary, #006EED);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  margin-top: 28px;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-top: 24px;
    margin-bottom: 89px;
    font-size: 12px;
  }
  &:hover {
    cursor: pointer;
  }

  &:focus {
   cursor: pointer;
  }
}


.image-container {
  position: relative;  /* This makes it the reference for absolute positioning */
  display: inline-block;
}

.design-right-arrow-box {
  position: absolute;  
  top: 50%;  /* Adjust as needed */
  right: 10px;  /* Place inside the image */
  transform: translateY(-50%); /* Centers vertically */
  background: lightgray;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  @media (max-width: 768px) {
    right: 5px;
    width: 23px;
    height: 23px;
  }
}

.design-left-arrow-box {
  position: absolute;  
  top: 50%;  /* Adjust as needed */
  left: 10px;  /* Place inside the image */
  transform: translateY(-50%); /* Centers vertically */
  background: lightgray;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  @media (max-width: 768px) {
    right: 5px;
    width: 23px;
    height: 23px;
  }
}
/*-------------------Design Page Styles End-------------------*/

.partnership-container {
  //max-width: 600px;
  margin: auto;
  text-align: center;

  h2 {
    color: var(--Accent-Neutral, #0B1623);
    text-align: center;
    font-family: Rubik;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
     @media (max-width: 768px) {
        font-size: 25px;
        }
  }

  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 112px;
    @media (max-width: 768px) {
        margin-top: 40px;
        }
    .logo-naadi {
      color: #000;
      margin-right: 8px;
      height:52px;
      width: 338px;
      @media (max-width: 768px) {
         height:26px;
      width: 164px;
        }
    }

    .logo-x {
      color: #000;
      text-align: center;
      font-family: Rubik;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 70px;
      padding-right: 65px;
      //margin-top: 112px;
      @media (max-width: 768px) {
        font-size: 20px;
        padding-left: 20px;
        padding-right: 20px;
  
        }
    }

    .logo-coohom {
      color: #007bff;
      height: 52px;
      width: 241px;
      @media (max-width: 768px) {
         height:26px;
      width: 120px;
        }
    }
  }
  .aboutus-text{
    margin-top: 100px;
    max-width: 1204px;
    p {
      color: #000;
      text-align: center;
      font-family: Rubik;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 15px;
        }
    }
    @media (max-width: 768px) {
        margin-top: 40px;
        max-width: 300px;
        }

  }
  
  
}